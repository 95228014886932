import React from 'react';
import { isWeb3NetWorkSupport } from '../../utils/utils';

export default function SwapStepContent({ status, step, amount, token, inputNetwork, outputNetwork, receiptHash }) {
    return (
        <div className="pp-stepper-content pt-5">
            {
                step === 1 && <div id="processStep1">
                    <h3 className='text-white mb-4'>Information</h3>
                    <div className="pp-stepper-body">
                        <p>You want to swap  {`${amount || 0} ${token.symbol}`} from {inputNetwork.name}&nbsp;to {outputNetwork.name}</p>
                        <div className='pp-stepper-notice primary'>
                            <div className="d-flex flex-wrap align-items-center justify-content-center">
                                <span className="text-purple me-2">You will receive</span>
                                {token.image && <img className="mx-2" height="12" src={`${token.image}`} alt={`token`} />}
                                <b className='text-white'>{`${amount || 0} ${token.symbol}`}</b>
                                <span className="badge ms-2 p-coin-tag">{outputNetwork.badge}</span>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-center mt-2">
                                <span className="text-purple me-2">Swap fee:</span>
                                <b className='text-white'>{`${token.fee} `} {inputNetwork.currency}</b>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                step === 2 && <div id="processStep2">
                    <h3 className='text-white mb-4'>Pre-authorization</h3>
                    <div className='pp-stepper-sub-title'>1<sup>st</sup> of 2 transactions required.</div>
                    <div className="pp-stepper-body">
                        <p>First transaction is the Pre-authorization step, where you allow the contract to swap your tokens up to the defined amount.</p>
                    </div>
                    <div className="ai-warning">
                        <i className="mdi mdi-alert me-1"></i>
                        <span>You will be asked to confirm that you allow the smart contract to swap <b>{amount}</b> {token.symbol} from your wallet.</span>
                    </div>
                </div>
            }
            {
                step === 3 && <div id="processStep3">
                    <h3 className='text-white mb-4'>Confirm</h3>
                    <div className="pp-stepper-body">
                        <p>Second transaction is the <b className='text-white'>Swap</b> step, where the provided amount of {token.symbol} tokens will be swapped via the contract.</p>
                    </div>
                    <div className="ai-warning">
                        <i className="mdi mdi-alert me-1"></i>
                        <span>This is the last transaction you need to make to finalize the swap.</span>
                    </div>
                </div>
            }
            {
                step === 4 && <div id="processStep4">
                    <h3 className='text-white mb-4'>Swap Submitted</h3>
                    {
                        status === "pending" &&
                        <>
                            <p className="ai-warning">
                                <i className="mdi mdi-alert me-1"></i>
                                <span>Please wait below for the completed swap transaction receipt from the {outputNetwork.name}.</span>
                            </p>
                            <p>The swap process can take several minutes. If you do not receive a transaction receipt please contact us for further support.</p>
                            <div className="lds-ripple"><div></div><div></div></div>
                        </>
                    }
                    {
                        status === "completed" &&
                        <div className='ai-success'>
                            <i className="mdi mdi-check text-success me-2"></i>
                            <span>{token.symbol} token swapped successfully!</span>
                        </div>
                    }
                    {
                        status === "fail" &&
                        <div className='ai-error'>
                            <i className="mdi mdi-alert text-warning me-2"></i>
                            <span>{token.symbol} token swapped fails!</span>
                        </div>
                    }

                    {
                        (inputNetwork.id === 'TRON' && status !== "pending") &&
                        <div className='ai-primary'>
                            <a className="text-white" href={`${receiptHash}`} target="_blank" rel="noopener noreferrer" style={{ wordBreak: 'break-word', textDecoration: 'none' }} >
                                <b>{receiptHash.substring(receiptHash.length - 64)}</b>
                            </a>
                        </div>
                    }
                    {(isWeb3NetWorkSupport(inputNetwork.id)) && status !== "pending" &&
                        <div className='ai-primary'>
                            <a className="text-white" href={`${receiptHash}`} target="_blank" rel="noopener noreferrer" style={{ wordBreak: 'break-word', textDecoration: 'none' }}>
                                <b>{receiptHash.substring(receiptHash.length - 64)}</b>
                            </a>
                        </div>
                    }
                </div>
            }
        </div>
    );
}