import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

export default function NetworkInputPanel({
    label,
    networkList,
    selectedNetwork,
    onNetworkChange,
    connected,
    isShow
}) {
    const [currentNetwork, setCurrentNetwork] = useState(selectedNetwork);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setCurrentNetwork(selectedNetwork);

    }, [selectedNetwork]);

    const handleNetworkChange = (network) => {
        setCurrentNetwork(network);
        onNetworkChange(network);
    };


    return (
        <>
            <div className="ai-control">
                <label>{label}</label>
                <div className="ai-input" onClick={() => setShow(isShow)} >
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <div className='ai-network-icon'>
                                <img alt='' height="24" style={{ maxWidth: '36px', maxHeight: '36px' }} src={`/images/networks/${currentNetwork.id}-icon.svg`} />
                                {
                                    (connected.id === currentNetwork.id) && <span className="ai-connected"></span>
                                }
                            </div>
                            <span className="text-white ms-3">{currentNetwork.name}</span>
                            {currentNetwork.badge && (
                                <span className={`badge ai-tag ai-tag-${currentNetwork.badge}`}>
                                    {/* {currentNetwork.badge} */}
                                </span>
                            )}
                        </div>
                        <div className='d-inline-flex align-items-center'>
                            <small className={`badge ai-tag ai-tag-${currentNetwork.badge}`}>{currentNetwork.badge}</small>
                            <i className="mdi mdi-chevron-right"></i>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size='sm' className='ai-modal' centered show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Network</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                        {networkList.map((value) => {
                            return (
                                <button
                                    type="button"
                                    key={value.id}
                                    disabled={value.name === currentNetwork.name}
                                    className='btn-pd justify-content-between w-100 px-4'
                                    onClick={() => { handleNetworkChange(value); setShow(false) }}
                                >
                                    <img className='me-3' width="26" src={`/images/networks/${value.id}-icon.svg`} alt={value.name} />
                                    <span className='me-auto'>{value.name}</span>
                                    {value.name === currentNetwork.name ? <i className="ms-auto mdi mdi-check text-white"></i> : <></>}
                                </button>
                            )
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
