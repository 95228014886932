export const NETWORK_LIST = [
    {
        id: 'ETH',
        name: 'Ethereum',
        badge: 'ERC20',
        currency: 'ETH',
        type: 'web3',
        defaultInput: false,
        defaultOutPut: false,
        abiBridgeVersion:'05',
        isShow: true
    },
    {
        id: 'BSC',
        name: 'Binance Smart Chain',
        badge: 'BEP20',
        currency: 'BNB',
        type: 'web3',
        defaultInput: true,
        defaultOutPut: false,
        abiBridgeVersion:'07',
        isShow: true
    },
    {
        id: 'VELAS',
        name: 'Velas',
        badge: 'VRC20',
        currency: 'VLX',
        type: 'web3',
        defaultInput: false,
        defaultOutPut: false,
        abiBridgeVersion:'05',
        isShow: true
    },
    //     {
    //     id: 'POLYGON',
    //     name: 'Polygon',
    //     badge: '',
    //     currency: 'MATIC',
    //     type: 'web3',
    //     defaultOutPut: true,
    //     defaultInput: false,
    // },
    // {
    //     id: 'KCC',
    //     name: 'Kucoin Community Chain',
    //     badge: 'KRC20',
    //     currency: 'KSC',
    //     type: 'web3',
    //     defaultOutPut: true,
    //     defaultInput: false,
    // },
    {
        id: 'ARB1',
        name: 'Arbitrum One',
        badge: 'ERC20',
        currency: 'ETH',
        type: 'web3',
        defaultOutPut: false,
        defaultInput: false,
        abiBridgeVersion:'07',
        isShow: false,
    },
    {
        id: 'PLS',
        name: 'Pulse Chain',
        badge: 'PRC20s',
        currency: 'PLS',
        type: 'web3',
        defaultOutPut: true,
        defaultInput: false,
        abiBridgeVersion:'07',
        isShow: true,
    },
];

export const CHAIN_IDS = {
    eth: [1, 3],
    bsc: [56, 97],
    ksc: [321,322],
    vlx: [106, 111],
    polygon:[137, 0],
    ARB1:[42161, 42170 ],
    PLS:[369 ],
};
