import axios from "axios";
import { BACKEND_URI, MODE} from '../../_configs';

export const getStatusSwap = async (srcNetwork,dstNetwork, txId) => {
  try {
    // http://bridge.myfantasy.cloud/api/status?srcNetwork=KCC&dstNetwork=BSC&txid=0x31b39719e9841dc99efc574a01c6fb25ad38ec5406c0b19f686b3bd5634dbe0d
    const result = await axios.get(
      `${BACKEND_URI[MODE]}/api/status?srcNetwork=${srcNetwork}&dstNetwork=${dstNetwork}&txid=${txId}`
    );
    // //console.log(result);
    if (result.status === 200) {
      return result.data.data;
    }

    return null;
  } catch (error) {
    const response = error.response;

    console.log(response);
    //    return response

    return null;
  }
};

