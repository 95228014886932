import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Web3 from "web3";

export default function DestinationInput({ inputNetwork, outputNetwork, onDestinationChange, isValid }) {
    const isConnectWallet = useSelector((state) => get(state, 'utils.isConnectWallet', false));
    const walletAddress = useSelector((state) => get(state, "utils.walletAddress", false));
    const walletUtils = useSelector((state) => get(state, 'utils.walletUtils', null));
    const [defaultValue, setDefaultValue] = useState("")
    const [readOnly, setReadOnly] = useState(false);
    const [isValidateAddress, setIsValidateAddress] = useState(false);
    const [isContractAddress, setIsContractAddress] = useState(false);

    useEffect(() => {
        if (inputNetwork.type == outputNetwork.type) {
            setDefaultValue(walletAddress);
            onDestinationChange(walletAddress);
        } else {
            setDefaultValue("");
            setReadOnly(false);
        }

    }, [inputNetwork, onDestinationChange, outputNetwork, walletAddress]);

    const isContract = useCallback(async (network) => {
        const result = await walletUtils.isContract(defaultValue, network);
        setIsContractAddress(result)
        isValid(!result);
        console.log(`isContract: ${result}`)
    }, [defaultValue, walletUtils, isValid])

    useEffect(() => {
        let check = false;
        if (outputNetwork.type === "web3") {
            check = Web3.utils.isAddress(defaultValue)
            setIsValidateAddress(check)
        }
        if (!isValidateAddress && check) {
            isContract(outputNetwork.id)
        }
        if (!isValidateAddress) {
            isValid(check)
        }

    }, [defaultValue, isContract, isValid, isValidateAddress, outputNetwork.id, outputNetwork.type])

    return (
        <>
            {
                isConnectWallet && <div className='ai-control'>
                    <label className='d-flex align-items-center justify-content-between'>
                        <span>Destination</span>
                        {(!isValidateAddress && defaultValue) &&
                            <small className="text-warning"><i className="mdi mdi-alert me-1"></i>Invalid address</small>
                        }
                        {(isValidateAddress && defaultValue && isContractAddress) &&
                            <small className="text-warning"><i className="mdi mdi-alert me-1"></i>Cannot bridge to a contract address</small>
                        }
                    </label>
                    <div className='ai-input-icon'>
                        <img alt='' src={`/images/networks/${outputNetwork.id}-icon.svg`} width={20} />
                        <input
                            onChange={(e) => {
                                setIsValidateAddress(false)
                                setIsContractAddress(false)
                                setDefaultValue(e.target.value)
                                onDestinationChange(e.target.value)
                            }
                            }
                            type="text"
                            className={`ai-inputx ${(!isValidateAddress && defaultValue) ? 'invalid' : ''}`}
                            placeholder={`Input your destination address`}
                            readOnly={readOnly}
                            value={defaultValue || ""}
                        />
                    </div>

                    <div className="mb-3 text-warning px-2 mt-2" style={{ fontSize: '13px', opacity: '0.7' }}>
                        <i className="mdi mdi-information-outline me-1"></i>
                        This is the destination address on the {outputNetwork.name}.
                    </div>
                </div>
            }


        </>
    );
}
