import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ACTION_CONST } from "../../constants";
import { useIsConnected, useWalletAddress, useWeb3Utils } from "../../hooks/useState";
import ConnectWallet from "../ConnectWallet";
import WalletInfo from "../WalletInfo";
import "./index.scss";

const AIHeader = () => {
  const dispatch = useDispatch();
  const isConnectWallet = useIsConnected();
  const walletAddress = useWalletAddress();
  const walletUtils = useWeb3Utils();
  const [amountBalance, setAmountBalance] = useState(0)

  useEffect(() => {
    if (walletUtils) {
      walletUtils.getBalanceAccount().then(balance => {
        setAmountBalance(balance)
      }).catch(err => console.log(err));

      const job = setInterval(() => {
        walletUtils.getBalanceAccount().then(balance => {
          setAmountBalance(balance)
        }).catch(err => console.log(err));
      }, 10 * 1000);
      dispatch({ type: ACTION_CONST.SET_JOB_GET_BALANCE, data: job })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletUtils])

  return (
    <Navbar className='ai-navbar' variant='dark' expand="md" fixed='top'>
      <Container>
        <Navbar.Brand href="https://pulsepad.io">
          <img src="/images/logo.png" alt="PulsePad" height={66} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarApp" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-md-auto mt-5 mt-md-0">
            {
              !isConnectWallet ?
                <ConnectWallet /> :
                <WalletInfo walletAddress={walletAddress} balance={amountBalance} />
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AIHeader;
