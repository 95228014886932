import React from 'react';
import AiButton from '../Button';

export default function AssetToken({ token, selected, onClickSelect }) {
    return (
        <AiButton
            disabled={token.symbol === selected}
            size={`md px-3 w-100 mb-3 ${token.symbol === selected ? 'selected' : ''}`}
            onClick={() => onClickSelect(token)}
            icon={<img src={`${token.image}`} alt="" title={token.symbol} height="36" />}
            buttonText={
                <>
                    <span className='ms-3'>{token.symbol}</span>
                    {token.symbol === selected ? <i className="ms-auto mdi mdi-check text-white"></i> : <span className='ms-auto'></span>}
                </>
            }
        />
    );
}
