
import axios from "axios";
import { BACKEND_URI, MODE} from '../../_configs';
import {ENDPOINTS} from '../../constants'
/**
 * Login
 * @param {Object} data
 */
export const getTokens= async () => {
  try {
    console.log(`${BACKEND_URI[MODE]}${ENDPOINTS.GET_ASSETS}`);

    const result = await axios.get(`${BACKEND_URI[MODE]}${ENDPOINTS.GET_ASSETS}`);

    if(result.status === 200){
      // debugger
      return {
        status: 200,
        data: result.data.data
      }
    }
    return  {
      status: 404,
      data: []
    }
   
  } catch (error) {

    console.log(error);
    return []
  }
 
};



