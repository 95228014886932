import React from 'react';
import AIHeader from './Header';
import AIFooter from './Footer';
import BlockUi from "react-block-ui"
import { useShowBlockUI } from '../hooks/useState';
export const PrivateLayout = ({ children, ...rest }) => {
    const showBlockUI = useShowBlockUI();
    return (
        <>
            <BlockUi tag="div" blocking={showBlockUI}>
                <AIHeader />
                <main>{children}</main>
                <AIFooter />
            </BlockUi>
        </>
    );
};