import { combineReducers } from 'redux';
import utils from './utils.reducer';
import tokens from './tokens.reducer';
import wallet from './wallet.reducer';
const reducers = combineReducers({
    utils,
    tokens,
    wallet
});

export default reducers;