import Web3 from "web3";
import window from "global";
import exactMath from "exact-math";
import erc20Abi from "../contracts/erc20.abi";

import bridgeAbi from "../contracts/bridge05.json";
import bridge07Abi from "../contracts/bridge07.json";
import {
  calculateBalanceBigNumber,
  calculateBalanceBigNumberTron,
  calculateBalanceSend,
  isWeb3NetWorkSupport,
} from "./utils";
import { BigNumber } from "bignumber.js";
import { helpers } from "./helpers";

import { NETWORK_LIST, STATUS } from "../constants";
import {
  IMAGE_URL,
  MODE,
  SWAP_SMART_CONTRACT,
  BRIDGE_07_SMART_CONTRACT,
  RPC,
} from "../_configs";
import { CHAIN_ID } from "../constants/chainId";

import { extensionName } from "../constants/values";

import axios from "axios";

export default class WalletExtensionUtils {
  constructor(ex) {
    this.web3 = null;
    this.tronWeb = null;
    this.extension = null;

    this.isWrongNetwork = false;
    this.extensionName = ex;
    this.network = "";
  }

  async connect(currentInputNetWork) {
    console.log("CONNECT CHAIN: ", currentInputNetWork);
    if (this.extensionName === extensionName.binanceExtension) {
      if (window.BinanceChain) {
        this.extension = window.BinanceChain;
        this.web3 = new Web3(window.BinanceChain);

        try {
          const envCheck = !(
            window.BinanceChain.chainId ===
              Web3.utils.numberToHex(CHAIN_ID.BSC[MODE]) ||
            window.BinanceChain.chainId ===
              Web3.utils.numberToHex(CHAIN_ID.ETH[MODE])
          );

          if (envCheck) {
            this.isWrongNetwork = true;
            return;
          }

          this.network = currentInputNetWork;

          await window.BinanceChain.enable();
          const addresses = await this.web3.eth.getAccounts();
          this.address = addresses[0];
        } catch (error) {
          console.error(error.message);
          this.web3 = null;
        }
      } else throw new Error("Detect Binance Extension failed!");

      return window.BinanceChain.chainId;
    } else if (
      this.extensionName === extensionName.metamask ||
      this.extensionName === extensionName.trustWallet
    ) {
      if (window.ethereum) {
        // console.log("get window.ethereum");
        this.extension = window.ethereum;
        this.web3 = new Web3(window.ethereum);

        // console.log("window.ethereum enable");
        await window.ethereum.enable();

        //check current network
        let envCheck;
        if (!isWeb3NetWorkSupport(currentInputNetWork)) {
          this.isWrongNetwork = true;
          return;
        }

        if (
          typeof currentInputNetWork === "string" &&
          currentInputNetWork.toUpperCase() === "ETH"
        ) {
          //connect with eth
          envCheck = !(
            window.ethereum.chainId ===
              Web3.utils.numberToHex(CHAIN_ID["ETH"][MODE]) ||
            window.ethereum.chainId === CHAIN_ID["ETH"][MODE] ||
            window.ethereum.networkVersion === CHAIN_ID.ETH[MODE] ||
            (!window.ethereum.chainId && !window.ethereum.networkVersion)
          );
        } else if (
          typeof currentInputNetWork === "string" &&
          currentInputNetWork.toUpperCase() === "BSC"
        ) {
          //connect with bsc
          envCheck = !(
            window.ethereum.chainId ===
              Web3.utils.numberToHex(CHAIN_ID["BSC"][MODE]) ||
            window.ethereum.chainId === CHAIN_ID["BSC"][MODE] ||
            window.ethereum.networkVersion === CHAIN_ID["BSC"][MODE]
          );
        } else if (
          typeof currentInputNetWork === "string" &&
          currentInputNetWork.toUpperCase() === "VELAS"
        ) {
          //connect with ARBTRIUM
          envCheck = !(
            window.ethereum.chainId ===
              Web3.utils.numberToHex(CHAIN_ID["VELAS"][MODE]) ||
            window.ethereum.chainId === CHAIN_ID["VELAS"][MODE] ||
            window.ethereum.networkVersion === CHAIN_ID["VELAS"][MODE]
          );
        }
        else if (
          typeof currentInputNetWork === "string" &&
          currentInputNetWork.toUpperCase() === "PLS"
        ) {
          //connect with PULES CHAIN
          envCheck = !(
            window.ethereum.chainId ===
              Web3.utils.numberToHex(CHAIN_ID["PLS"][MODE]) ||
            window.ethereum.chainId === CHAIN_ID["PLS"][MODE] ||
            window.ethereum.networkVersion === CHAIN_ID["PLS"][MODE]
          );
        }
        if (envCheck) {
          this.isWrongNetwork = true;
          return;
        }

        try {
          this.network = currentInputNetWork;
          const addresses = await this.web3.eth.getAccounts();
          this.address = addresses[0];
        } catch (error) {
          console.error(error.message);
          this.web3 = null;
        }
      } else throw new Error("Detect Wallet failed!");

      return window.ethereum.chainId;
    }
  }

  accountsChanged(callback) {
    // const this = this;
    if (this.extension) {
      this.extension.on("accountsChanged", function (accounts) {
        this.address = accounts[0];
        callback(accounts[0]);
      });
    }
  }

  chainChanged(callback) {
    // const this = this;
    // debugger;
    this.extension.on("chainChanged", function (chainId) {
      // console.log("chainId==>", chainId);
      this.extension = window.ethereum;
      this.web3 = new Web3(window.ethereum);
      callback(chainId);
    });
  }

  isConnected() {
    return this.web3 !== null;
  }
  checkWrongNetwork() {
    return this.isWrongNetwork;
  }

  //get current chain of extension
  getCurrentChainId() {
    return Number(window.ethereum.networkVersion);
  }

  async getTokenBalance({ tokenAddress, decimal }) {
    try {
      if (isWeb3NetWorkSupport(this.network)) {
        const tokenContract = new this.web3.eth.Contract(
          erc20Abi,
          tokenAddress
        );

        const tokenBalance = await tokenContract.methods
          .balanceOf(this.address)
          .call();
        // debugger
        return exactMath.div(Number(tokenBalance), exactMath.pow(10, decimal));
      }
    } catch (error) {
      console.log(error);
    }

    return 0;
  }

  //call approve smart contract use token f user
  async approveToken(
    { tokenContractAddress, spenderAddress, amount, decimal, inputNetWork },
    callback
  ) {
    if (
      !tokenContractAddress ||
      !spenderAddress ||
      !amount ||
      inputNetWork !== this.network
    ) {
      callback({ status: STATUS.APPROVE_FAILS });
      return;
    }

    callback({ status: STATUS.APPROVING });
    //approve token erc20
    if (isWeb3NetWorkSupport(this.network)) {
      amount = calculateBalanceBigNumber(amount, decimal || 18);
      try {
        const tokenContract = new this.web3.eth.Contract(
          erc20Abi,
          tokenContractAddress
        );
        callback({
          status: STATUS.APPROVING,
        });
        const amountInHex = "0x" + amount.toString(16);

        const gasPrice = await this.getGasPrice();

        // console.log(amountInHex);
        await tokenContract.methods
          .approve(spenderAddress, amountInHex)
          .send({ from: this.address, gasPrice });
        // }
        callback({
          status: STATUS.APPROVED,
        });
      } catch (error) {
        callback({
          status: STATUS.APPROVE_FAILS,
        });
        console.log(error);
      }
    }
  }

  /**
   *
   * @param {*} param
   * @param {*} callback
   */
  async swap(
    {
      tokenAddress,
      amount,
      toAddress,
      decimal,
      fee,
      desNetwork,
      bridgeContractAddress,
    },
    callback
  ) {
    callback({
      status: STATUS.APPROVING,
    });

    // const BRIDGE = `${this.network}_NETWORK`.toUpperCase();

    if (isWeb3NetWorkSupport(this.network)) {
      const contract = new this.web3.eth.Contract(
        bridgeAbi,
        bridgeContractAddress
      );

      // const swapFee = this.calculateSendAmount(Number(fee));
      fee = this.calculateSendAmount(Number(fee));
      // amount = calculateBalanceBigNumber(amount, decimal || 18).toString();

      amount = amount = calculateBalanceBigNumber(amount, decimal || 18);
      const amountInHex = "0x" + amount.toString(16);

      const gasPrice = await this.getGasPrice();

      contract.methods
        .swap(tokenAddress, desNetwork, toAddress, amountInHex)
        .send({ from: this.address, value: fee, gasPrice })
        .on("transactionHash", (hash) => {
          callback({
            status: STATUS.SWAP_SUBMITTING,
            txID: hash,
          });
        })
        .on("error", (error) => {
          console.log(error);
          callback({
            status: STATUS.SWAP_FAILS,
          });
        })
        .then((receipt) => {
          if (receipt.status === true) {
            callback({
              status: STATUS.SWAP_SUCCESS,
              txID: receipt.transactionHash,
            });
          } else callback({ status: STATUS.SWAP_FAILS });
        })
        .catch((err) => {
          console.log(err);
          callback({ status: STATUS.SWAP_FAILS });
        });
      // return executeSwapResult;
    }
  }
  /**
   *
   * @param {*} param0
   * @param {*} callback
   * @returns
   */

  //get current account
  getCurrentAddress() {
    return this.address;
  }

  //get current network connect
  getCurrentNetWork() {
    return this.network;
  }

  calculateSendAmount(amount) {
    return this.web3.utils.toWei(amount.toString(), "ether");
  }

  fromWei(amount) {
    return this.web3.utils.fromWei(amount.toString(), "ether");
  }

  async getBalanceAccount() {
    const symbol = NETWORK_LIST.find((e) => e.id === this.network).currency;

    try {
      let balance = 0;
      if (isWeb3NetWorkSupport(this.network)) {
        balance = await this.web3.eth.getBalance(this.address);
        switch (this.network) {
          case "BSC":
            return (
              helpers.formatNumberDownRoundWithExtractMax(
                this.fromWei(Number(balance)),
                6
              ) + ` ${symbol}`
            );
          case "ETH":
            return (
              helpers.formatNumberDownRoundWithExtractMax(
                this.fromWei(Number(balance)),
                8
              ) + ` ${symbol}`
            );
          case "VELAS":
            return (
              helpers.formatNumberDownRoundWithExtractMax(
                balance / 10 ** 18,
                4
              ) + ` ${symbol}`
            );
            case "PLS":
            return (
              helpers.formatNumberDownRoundWithExtractMax(
                balance / 10 ** 18,
                4
              ) + ` ${symbol}`
            );
          default:
            break;
        }

        return balance + ` ${symbol}`;
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  //add function get getAllowance
  async getAllowance(tokenAddress, contractAddress) {
    if (isWeb3NetWorkSupport(this.network)) {
      const tokenContract = new this.web3.eth.Contract(erc20Abi, tokenAddress);

      const allocationNumber = await tokenContract.methods
        .allowance(this.address, contractAddress)
        .call();
      const decimal = await tokenContract.methods.decimals().call();
      return new BigNumber(allocationNumber.toString())
        .dividedBy(10 ** Number(decimal))
        .toString();
    }
  }

  async getInfo() {

      const versionContract  = this.getVersionApiContractUse(this.network)
      if (versionContract === 1) {
        const data05 = await this.getInfoBridgeContract(this.network)
        console.log(data05);
        return data05
      }
      if (versionContract === 2) {
        const data07 = await this.getInfoBridgeContractWithRange(this.network)
        console.log(data07);
        return data07
      }
  }

  //check address is smart contract ?
  async isContract(address, network) {
    if (network === "ETH") {
      const web3 = new Web3(RPC.ETH_NETWORK[MODE]);
      const code = await web3.eth.getCode(address);
      return code !== "0x";
    } else if (network === "BSC") {
      const web3 = new Web3(RPC.BSC_NETWORK[MODE]);
      const code = await web3.eth.getCode(address);
      return code !== "0x";
    } else if (network === "VELAS") {
      const web3 = new Web3(RPC.VLX_NETWORK[MODE]);
      const code = await web3.eth.getCode(address);
      return code !== "0x";
    } else if (network === "POLYGON") {
      const web3 = new Web3(RPC.POLYGON_NETWORK[MODE]);
      const code = await web3.eth.getCode(address);
      return code !== "0x";
    }
    else if (network === "PLS") {
      const web3 = new Web3(RPC.PLS_NETWORK[MODE]);
      const code = await web3.eth.getCode(address);
      return code !== "0x";
    }
  }

  async getGasPrice() {
    const promises = [
      (async () => {
        const gasPrice = await this.web3.eth.getGasPrice();
        return this.web3.utils.fromWei(gasPrice, "gwei");
      })(),
    ];

    if (this.network === "POLYGON") {
      promises.push(
        (async () => {
          try {
            const response = await axios.get(
              "https://gasstation-mainnet.matic.network/v2"
            );
            return response.data.fast.maxFee;
          } catch (error) {
            const lastGasPrice = localStorage.getItem("lastGasPrice");
            if (lastGasPrice) {
              return lastGasPrice;
            } else {
              const gasPrice = await this.web3.eth.getGasPrice();
              return (
                Math.ceil(
                  Math.round(this.web3.utils.fromWei(gasPrice, "gwei") || 0)
                ) * 2
              );
            }
          }
        })()
      );
    }

    const result = await Promise.all(promises);
    console.log(result);
    const fasterGasPrice = Math.ceil(Math.max(...result));

    if (this.network === "POLYGON") {
      localStorage.setItem("lastGasPrice", fasterGasPrice);
    }

    console.log(`gas price: ${fasterGasPrice} gwei`);
    return this.web3.utils.toWei(`${fasterGasPrice}`, "gwei");
  }

  //getInfo of bridge contract using bridge abi version 07
  async getInfoBridgeContractWithRange(network) {
    let promises = [];
    let tempToken = [];
    let paused = false;

    const BRIDGE = `${network}_NETWORK`.toUpperCase();
    try {
      const bridge07Contract = new this.web3.eth.Contract(
        bridge07Abi,
        BRIDGE_07_SMART_CONTRACT[BRIDGE][MODE]
      );
      const swapPairCount = await bridge07Contract.methods
        .swapPairCount()
        .call({ from: this.address });

      for (let i = 0; i < swapPairCount; i = i + 30) {
        let startIndex = i;
        let count = 30;

        if (swapPairCount < startIndex + count) {
          count = swapPairCount - startIndex;
        }

        promises.push(
          bridge07Contract.methods
            .infoRange(startIndex, count)
            .call({ from: this.address })
        );
        promises.push(
          bridge07Contract.methods
            .infoEXRange(startIndex, count)
            .call({ from: this.address })
        );
      }

      if (promises.length > 0) {
        const results = await Promise.all(promises);

        for (let i = 0; i < promises.length; i = i + 2) {
          const infoRange = results[i];
          const infoExRange = results[i + 1];

          console.log(infoExRange);
          for (let i = 0; i < infoRange[1].length; i++) {
            const decimals = Number(infoRange[4][i]);
            const symbol = infoRange[3][i];

            //infoEx
            const balance = infoExRange[0][i];
            const fee = exactMath.div(
              Number(infoExRange[1][i]),
              exactMath.pow(10, 18)
            );

            const swapped = exactMath.div(
              Number(infoExRange[3][i]),
              exactMath.pow(10, decimals)
            );

            const limit = exactMath.div(
              Number(infoExRange[2][i]),
              exactMath.pow(10, decimals)
            );

            const addressLimit = exactMath.div(
              Number(infoExRange[4][i]),
              exactMath.pow(10, decimals)
            );
            const addressSwapped = exactMath.div(
              Number(infoExRange[5][i]),
              exactMath.pow(10, decimals)
            );
            tempToken.push({
              contractAddress: infoRange[1][i],
              networks: infoRange[2][i],
              symbol,
              decimals,
              paused: infoRange[5][i],
              balance: balance,
              fee,
              limit,
              swapped,
              addressLimit,
              addressSwapped,
              image: `${IMAGE_URL}` + symbol + ".png",
              bridgeContractAddress: BRIDGE_07_SMART_CONTRACT[BRIDGE][MODE],
              srcNetwork: (network ==='BSC') ? 'BSC2': network ,
            });
          }
          paused = infoRange[0]
        }
        
      }
    } catch (error) {
      console.log(error);
    }
    console.log(tempToken);
    return {
      paused,
      tokens: tempToken.filter((item) => item.paused == false),
    };
  }
  //getInfo of bridge contract using bridge abi version 05
  async getInfoBridgeContract(network) {
    let promises = [];
    let tempToken = [];
    let paused = false;
    const BRIDGE = `${network}_NETWORK`.toUpperCase();
    try {
        const bridgeContract = new this.web3.eth.Contract(
          bridgeAbi,
          SWAP_SMART_CONTRACT[BRIDGE][MODE]
        );

        promises.push(
          bridgeContract.methods.info().call({ from: this.address })
        );
        promises.push(
          bridgeContract.methods.infoEX().call({ from: this.address })
        );

        const results = await Promise.all(promises);
        const info = results[0];
        const infoEx = results[1];
          debugger
        for (let i = 0; i < info[1].length; i++) {
          const decimals = Number(info[4][i]);
          const symbol = info[3][i];

          //infoEx
          const balance = infoEx[0][i];
          const fee = exactMath.div(
            Number(infoEx[1][i]),
            exactMath.pow(10, 18)
          );

          const swapped = exactMath.div(
            Number(infoEx[3][i]),
            exactMath.pow(10, decimals)
          );

          const limit = exactMath.div(
            Number(infoEx[2][i]),
            exactMath.pow(10, decimals)
          );

          const addressLimit = exactMath.div(
            Number(infoEx[4][i]),
            exactMath.pow(10, decimals)
          );
          const addressSwapped = exactMath.div(
            Number(infoEx[5][i]),
            exactMath.pow(10, decimals)
          );
          tempToken.push({
            contractAddress: info[1][i],
            networks: info[2][i],
            symbol,
            decimals,
            paused: info[5][i],
            balance: balance,
            fee,
            limit,
            swapped,
            addressLimit,
            addressSwapped,
            image: `${IMAGE_URL}` + symbol + ".png",
            bridgeContractAddress: SWAP_SMART_CONTRACT[BRIDGE][MODE],
          });
        }
        paused = info[0];
    } catch (error) {
      console.log(error);
    }
    return {
      paused,
      tokens: tempToken.filter((item) => item.paused == false),
    };

  }

  getVersionApiContractUse(network){
    const nw = NETWORK_LIST.find(e=> e.id ===  network)
    if(!nw){
      throw Error('Net work does not support!')
    }
    if(nw.abiBridgeVersion === '05'){
      return 1
    }
    if(nw.abiBridgeVersion === '07'){
      return 2
    }
  }
}
