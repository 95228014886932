import { BigNumber } from "bignumber.js";
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import AmountInputPanel from '../../component/AmountInputPanel';
import AssetPanel from '../../component/AssetPanel';
import ConnectWallet from '../../component/ConnectWallet';
import DestinationInput from '../../component/DestinationInput';
import NetworkInputPanel from '../../component/NetworkInputPanel';
import { ACTION_CONST, LIMIT_VALUE, NETWORK_LIST } from '../../constants';
import { useIsConnected, useWeb3Utils } from '../../hooks/useState';
import StepModal from './StepModal';
import "./index.scss";

const defaultInputNetwork = NETWORK_LIST.find(e => e.defaultInput === true && e.isShow === true);
const defaultOutputNetwork = NETWORK_LIST.find(e => e.defaultOutPut === true && e.isShow === true);
const networkFilter = NETWORK_LIST.filter(e=>e.isShow === true)

const BridgePortalPage = (props) => {
    const dispatch = useDispatch();
    const walletUtils = useWeb3Utils()
    const isConnectWallet = useIsConnected();
    const [listTokens, setListTokens] = useState([])
    const [amount, setAmount] = useState(0);
    const [destinationAddress, setDestinationAddress] = useState("");
    const [inputNetwork, setInputNetwork] = useState(defaultInputNetwork);
    const [outputNetwork, setOutputNetwork] = useState(defaultOutputNetwork);
    const [tokenSelected, setTokenSelected] = useState({});
    const [isValidAddress, setIsValidAddress] = useState(false);
    const [enableSwapButton, setEnableSwapButton] = useState(false);
    const [currentNetWork, setCurrentNetWork] = useState("");
    const [isClearAmount, setIsClearAmount] = useState(false)
    const [pauseSwap, setPauSwap] = useState(true);
    const [showStepModal, setShowStepModal] = useState(false);
    const [networksList, setNetworksList] = useState(networkFilter);
    const [outputNetworksList, setOutputNetworksList] = useState(networkFilter.filter(e => e.id === 'PLS'));

    const handleInputAmountChange = (value) => {
        setAmount(value);
    };

    useEffect(() => {
        if (walletUtils) {
            const network = walletUtils.getCurrentNetWork();
            setCurrentNetWork(networksList.find(e => e.id === network));
        }
       
    }, [inputNetwork, walletUtils]);


    //update 
    useEffect(()=>{
        if(inputNetwork.id === "PLS"){ 
            setOutputNetworksList(networkFilter);
            setNetworksList(networkFilter.filter(e => e.id === 'PLS'));
        }
        else{
            setOutputNetworksList(networkFilter.filter(e => e.id === 'PLS'));
            setNetworksList(networkFilter)
        }
        
    },[inputNetwork])

    useEffect(() => {
        if (isConnectWallet && currentNetWork) {
            const n = networkFilter.filter(e => e.id !== currentNetWork.id)
            return setNetworksList(n)
        }
        return setNetworksList(networkFilter)
    }, [isConnectWallet, currentNetWork])


    //check enable swap button
    useEffect(() => {
        if (walletUtils) {
            // const currentNetWork =  walletUtils.getCurrentNetWork()
            if (currentNetWork && tokenSelected) {
                if (Number(amount) > 0 && currentNetWork.id === inputNetwork.id) {
                    let { swapped, limit } = tokenSelected;
                    //check address is admin token
                    if (limit === 0) limit = LIMIT_VALUE.MAX
                    const bigAmount = BigNumber(`${amount}`)
                    const bigMax = BigNumber(`${limit - swapped}`);
                    if (bigAmount.gte(0) && bigAmount.lte(bigMax)) {
                        setEnableSwapButton(true)
                    } else {
                        setEnableSwapButton(false)
                    }
                    return;
                } else {
                    setEnableSwapButton(false)
                }
                //if token selected pause disable 
            }
        } else {
            setEnableSwapButton(false)
        }
    }, [amount, currentNetWork, walletUtils, inputNetwork, tokenSelected])

    const handleNetworkSwap = () => {
    
        setInputNetwork(outputNetwork);
       
        setOutputNetwork(inputNetwork);
        
       
    };

    const handleInputNetworkChange = (value) => {
        if (value === outputNetwork) {
            handleNetworkSwap(value);
        } else {
            setInputNetwork(value);
        }
    };

    const handleOutputNetworkChange = (value) => {
        if (value === inputNetwork) {
            handleNetworkSwap(value);
        } else {
            setOutputNetwork(value);
        }
    };


    //handle select Token bridge
    const handleSelectedToken = (value) => {
        setTokenSelected(value)
    }

    //
    const handleOnchangeDestination = useCallback((value) => {
        setDestinationAddress(value);
    }, [])

    const handleCheckValidToAddress = (value) => {
        // console.log(value);
        setIsValidAddress(value)
    }

    //
    useEffect(() => {
        dispatch({
            type: ACTION_CONST.CURRENT_INPUT_NETWORK,
            data: inputNetwork.id
        })
        if (currentNetWork && currentNetWork.id !== inputNetwork.id) {
            toast.warn(`Wrong network You need connect to ${networksList.find(e => e.id === currentNetWork.id).name}!`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputNetwork]);

    //get list token
    useEffect(() => {
        if (walletUtils)
            walletUtils.getInfo().then(data => {
                if (data) {
                    const listTokensTemp = data.tokens.filter(a =>
                        (
                            outputNetwork.id.toLocaleUpperCase() === 'BSC' &&
                            (a.networks.toLocaleUpperCase() === 'BSC' || a.networks.toLocaleUpperCase() === 'BSC2')
                        ) ||
                        (
                            outputNetwork.id.toLocaleUpperCase() !== 'BSC' &&
                            a.networks.toLocaleUpperCase() === outputNetwork.id.toLocaleUpperCase()
                        )
                    );


                    setListTokens(listTokensTemp);
                    setPauSwap(data.paused)
                }
            })
    }, [walletUtils, outputNetwork])

    //set default token
    useEffect(() => {
        if (listTokens.length > 0) {
            if (!tokenSelected.symbol)
                setTokenSelected(listTokens[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listTokens])

    const handleClearAmount = useCallback(() => {
        setAmount(0);
        setIsClearAmount(true)
    }, [])

    const handleSwapped = (tokenAddress) => {
        // setIsSwappedSDone(tokenAddress)
        if (tokenAddress && walletUtils) {
            walletUtils.getInfo().then(data => {
                if (data) {
                    const listTokensTemp = data.tokens.filter(a =>
                        (
                            outputNetwork.id.toLocaleUpperCase() === 'BSC' &&
                            (a.networks.toLocaleUpperCase() === 'BSC' || a.networks.toLocaleUpperCase() === 'BSC2')
                        ) ||
                        (
                            outputNetwork.id.toLocaleUpperCase() !== 'BSC' &&
                            a.networks.toLocaleUpperCase() === outputNetwork.id.toLocaleUpperCase()
                        )
                    );
                    setListTokens(listTokensTemp);
                    setPauSwap(data.paused)
                    const defaultToken = data.tokens.find(
                        e => e.contractAddress === tokenAddress &&
                            e.networks === outputNetwork.id.toLocaleUpperCase()
                    );
                    if (defaultToken) {
                        setTokenSelected(defaultToken)
                    }
                }
            })
        }
    }
    return (
        <>
            <Container className='overflow-hidden'>
                <h1 className="page-title text-center mb-4" data-text="WELCOME TO PULSEPAD">PULSEPAD <span>BRIDGE</span></h1>
                <Row className="justify-content-center">
                    <Col xxl="7" xl="8" lg="10" className="px-xxl-4">
                        <div className="text-center">
                            {
                                (!isConnectWallet) && <div className='ai-warning'>
                                    <span role="img" aria-labelledby="">⚡️</span> Please connect your wallet to swap.
                                </div>
                            }
                        </div>
                        <AssetPanel tokenList={listTokens}
                            onChangeToken={handleSelectedToken}
                            tokenSelected={tokenSelected}
                            tokens={listTokens}
                        />
                        <div className='network-inputs'>
                            <NetworkInputPanel
                                label="From"
                                networkList={networksList}
                                selectedNetwork={inputNetwork}
                                onNetworkChange={handleInputNetworkChange}
                                isConnectWallet={isConnectWallet}
                                connected={currentNetWork}
                                isShow={!isConnectWallet}
                            />
                            <button disabled={isConnectWallet}
                                type="button"
                                className="ai-swap"
                                onClick={handleNetworkSwap}
                            >
                                <i className="mdi mdi-swap-horizontal"></i>
                            </button>
                            <NetworkInputPanel
                                label="To"
                                networkList={outputNetworksList}
                                selectedNetwork={outputNetwork}
                                onNetworkChange={handleOutputNetworkChange}
                                connected={currentNetWork}
                                isShow={!isConnectWallet}
                            />
                        </div>
                        <AmountInputPanel
                            label="Amount"
                            onAmountChange={handleInputAmountChange}
                            network={inputNetwork}
                            token={tokenSelected}
                            isClearAmount={isClearAmount}
                            pauseSwap={pauseSwap}
                        />
                        {/* <AssetBalance token={tokenSelected} network={inputNetwork} /> */}
                        <DestinationInput
                            outputNetwork={outputNetwork}
                            inputNetwork={inputNetwork}
                            onDestinationChange={handleOnchangeDestination}
                            isValid={handleCheckValidToAddress}
                        />
                        <div className='pt-2'>
                            {
                                !isConnectWallet ?
                                    <ConnectWallet className='btn-pd w-100 justify-content-center' />
                                    :
                                    <button
                                        className="btn-pd w-100"
                                        disabled={!enableSwapButton || !isValidAddress || pauseSwap || get(tokenSelected, "paused", true)}
                                        onClick={() => setShowStepModal(true)}
                                    >
                                        <span>Swap</span>
                                    </button>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <StepModal amount={amount}
                token={tokenSelected}
                destination={destinationAddress}
                inputNetwork={currentNetWork}
                outputNetwork={outputNetwork}
                clearAmount={handleClearAmount}
                handleSwapped={handleSwapped}
                show={showStepModal}
                onClose={() => setShowStepModal(false)}
            />
        </>
    );
};

export default BridgePortalPage;
