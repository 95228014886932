export const CHAIN_ID = {
  BSC: {
    TESTNET: 97,
    MAINNET: 56,
  },
  ETH: {
    TESTNET: 3,
    MAINNET: 1,
  },
  KCC :{
    TESTNET: 322,
    MAINNET: 321,
  },
  VELAS:{
    TESTNET: 111,
    MAINNET: 106,
  },
  POLYGON:{
    TESTNET: 0,
    MAINNET: 137,
  },
  ARB1:{
    TESTNET: 0,
    MAINNET: 42161,
  },
  PLS:{
    TESTNET: 0,
    MAINNET: 369,
  },
};
