import React from 'react';

export default function AssetSelected({ token, onSelect }) {
    return (
        <div className="ai-control">
            <label>Asset</label>
            <div className="ai-input d-flex align-items-center justify-content-between" onClick={onSelect}>
                <div className="d-inline-flex align-items-center">
                    {
                        (token && token.symbol) ? <>
                            <img height="24" src={token.image} alt={token.symbol} />
                            <span className='ms-2'>{token.symbol}</span>
                        </> : <>
                            <img height="24" src="/images/logo.png" alt='' />
                            <span className='ms-2'>Select asset</span>
                        </>
                    }
                </div>
                <i className="mdi mdi-chevron-right"></i>
            </div>
        </div>
    );
}
