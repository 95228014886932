
import React from 'react';
import ButtonStep from './ButtonStepComponent';

export default function SwapLineStepTab({ step, currentStep, status }) {
    return (
        <div className="d-flex justify-content-center" role="tablist">
            <ButtonStep divClass={`pp-step ${step === 1 ? "current" : ""} ${currentStep >= 1 ? "completed" : ""}`}
                btnText={currentStep === 0 ? "Confirmation" : "Confirmed"}
            />
            <ButtonStep divClass={`pp-step ${step === 2 ? "current" : ""} ${currentStep >= 2 ? "completed" : ""}`}
                btnText={currentStep <= 1 ? "Authorization" : "Approved"}
            />
            <ButtonStep divClass={`pp-step ${step === 3 ? "current" : ""} ${currentStep >= 3 ? "completed" : ""}`}
                btnText={currentStep <= 2 ? "Depositing" : "Deposited"}
            />
            <ButtonStep divClass={`pp-step ${step === 4 ? "current" : ""} ${currentStep >= 4 ? status : ""}`}
                btnText={step === 4 ? status : "Completion"}
            />
        </div>
    );
}
