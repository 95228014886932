
import React, { useState } from 'react';
import BlockUi from "react-block-ui";
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { EXPLORER, MODE } from '../../_configs';
import { ACTION_CONST, MESSAGES, STATUS } from '../../constants';
import { useShowBlockUI, useWeb3Utils } from '../../hooks/useState';
import { getStatusSwap } from '../../redux/services/bridge.api';
import SwapLineStepTab from './SwapLineStepTab';
import SwapStepContent from './SwapStepContentTab';

const StepModal = ({ amount, token, inputNetwork, outputNetwork, destination, clearAmount, handleSwapped, show, onClose }) => {
    const dispatch = useDispatch();
    const showBlockUI = useShowBlockUI()
    const walletUtils = useWeb3Utils()
    const [currentStep, setCurrentStep] = useState(0);
    const [step, setStep] = useState(1);
    const [receiptHash, setReceiptHash] = useState("");
    const [status, setStatus] = useState("pending");

    const handleApproveStep = async () => {
        if (!walletUtils) return;
        dispatch({
            type: ACTION_CONST.REQUEST_SUBMIT,
        });
        const allowance = await walletUtils.getAllowance(token.contractAddress, token.bridgeContractAddress);
        if (Number(allowance) >= Number(amount)) {
            setStep(3);
            setCurrentStep(2);
            dispatch({
                type: ACTION_CONST.REQUEST_DONE,
            });
            return;
        }
        walletUtils.approveToken(
            {
                tokenContractAddress: token.contractAddress,
                spenderAddress: token.bridgeContractAddress,
                amount: Number(amount),
                inputNetWork: inputNetwork.id,
                decimal: token['decimals']
            },
            (data) => {
                if (data.status === STATUS.APPROVED) {
                    toast.success(MESSAGES.APPROVE_ASSET_SUCCESS);
                    setStep(3)
                    setCurrentStep(2)
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                }
                if (data.status === STATUS.APPROVE_FAILS) {
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                    toast.error(MESSAGES.APPROVE_ASSET_FAIL);
                }
            }
        );
    }

    const handleSwapClickStep = () => {
        if (!walletUtils) return;
        dispatch({
            type: ACTION_CONST.REQUEST_SUBMIT,
        });
        walletUtils.swap(
            {
                amount: Number(amount),
                tokenAddress: token.contractAddress,
                toAddress: destination,
                decimal: token['decimals'],
                fee: token.fee,
                desNetwork: token.networks,
                bridgeContractAddress: token.bridgeContractAddress,
            },
            (result) => {
                if (result.status === STATUS.SWAP_SUCCESS) {
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                    setStep(4)
                    setCurrentStep(3)
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                    handleSwapped(token.contractAddress)
                    getStatus(token.srcNetwork || inputNetwork.id, token.networks, result.txID);
                    let strLink = "";
                    if (inputNetwork.id === 'TRON') {
                        strLink = `${EXPLORER[inputNetwork.id.toLocaleUpperCase()][MODE]}/#/transaction/${result.txID}`
                    } if (inputNetwork.id === 'BSC' || inputNetwork.id === 'BSC2') {
                        strLink = `${EXPLORER['BSC'][MODE]}/tx/${result.txID}`
                    }
                    if (inputNetwork.id === 'ETH') {
                        strLink = `${EXPLORER[inputNetwork.id.toLocaleUpperCase()][MODE]}/tx/${result.txID}`
                    }
                    if (inputNetwork.id === 'KCC') {
                        strLink = `${EXPLORER[inputNetwork.id.toLocaleUpperCase()][MODE]}/tx/${result.txID}`
                    }
                    if (inputNetwork.id === 'VLX') {
                        strLink = `${EXPLORER[inputNetwork.id.toLocaleUpperCase()][MODE]}/tx/${result.txID}`
                    }
                    if (inputNetwork.id === 'ARB1') {
                        strLink = `${EXPLORER[inputNetwork.id.toLocaleUpperCase()][MODE]}/tx/${result.txID}`
                    }
                    toast.success(<div>
                        <div className='mb-2'>`Swap successfully with txID ${result.txID}`</div>
                        <div>
                            <a href={strLink} rel="noopener noreferrer" target="_blank">View details</a>
                        </div>
                    </div>);
                }
                if (result.status === STATUS.SWAP_FAILS) {
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                    toast.error(MESSAGES.SWAP_FAIL);
                }
            }
        );
    }

    //get status button
    const getStatus = (srNetwork, desNetwork, txID) => {
        const job = setInterval(() => {
            getStatusSwap(srNetwork, desNetwork, txID).then(data => {
                if (data) {
                    setReceiptHash(data["receiptHash"]);
                    setStatus(data["status"].toLowerCase());
                    if (data["status"].toLowerCase() === "completed" || data["status"].toLowerCase() === "fail") {
                        setCurrentStep(4);
                        clearInterval(job);
                    }
                }
            })
        }, 10 * 1000)
    }

    const handleClear = () => {
        setCurrentStep(0);
        setStep(1);
        setStatus("pending");
        setReceiptHash("")
        clearAmount();
        onClose();
        setStep(1);
    }

    return (
        <Modal size='xl' className='ai-modal' show={show} onHide={() => { onClose(); setStep(1); }}>
            <Modal.Header closeButton>
                <Modal.Title>Bridge process</Modal.Title>
            </Modal.Header>
            <BlockUi tag="div" blocking={showBlockUI} className="block-ui-step-modal">
                <Modal.Body>
                    <div className="ai-stepper text-center">
                        <SwapLineStepTab step={step} currentStep={currentStep} status={status} />
                        <SwapStepContent status={status}
                            step={step} amount={amount} token={token} receiptHash={receiptHash}
                            inputNetwork={inputNetwork} outputNetwork={outputNetwork} />
                    </div>
                    <div className="text-center pt-5">
                        {
                            step === 1 &&
                            <button type="button" className='btn-pd px-5 d-inline-flex' onClick={() => { setStep(2); setCurrentStep(1) }}>
                                <span>Confirm</span>
                            </button>
                        }
                        {
                            step === 2 &&
                            <button type="button" className='btn-pd px-5 d-inline-flex' onClick={handleApproveStep}>
                                <span>Approve</span>
                            </button>
                        }
                        {
                            step === 3 &&
                            <button type="button" className='btn-pd px-5 d-inline-flex' onClick={async () => handleSwapClickStep()}>
                                <span>Submit</span>
                            </button>
                        }
                        {
                            currentStep === 4 &&
                            <button type="button" className='btn-pd px-5 d-inline-flex' onClick={handleClear}>
                                <span>Done</span>
                            </button>
                        }
                    </div>
                </Modal.Body>
            </BlockUi>
        </Modal>
    )
}
export default StepModal;