import React, { useEffect, useState } from 'react';
import AssetSelected from '../AssetSelected';
import AssetSelectModal from '../AssetSelectModal';

export default function AssetPanel({ onChangeToken, tokenSelected, tokens }) {
    const [token, setToken] = useState({});
    const [showModalSelect, setShowModalSelect] = useState(false);

    useEffect(() => {
        if (tokens.length > 0) {
            if (tokenSelected.symbol) {
                setToken(tokenSelected)
            } else {
                setToken(tokens[0])
            }
        }
    }, [tokens, tokenSelected])
    return (
        <>
            <AssetSelected token={token} onSelect={() => setShowModalSelect(true)} />
            <AssetSelectModal show={showModalSelect} onClose={() => setShowModalSelect(false)} onClickSelect={onChangeToken} listTokens={tokens} selected={token.symbol} />
        </>
    );
}
