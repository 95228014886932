import React, { useState } from "react";

const Search = ({ onSearch, placeholder = "Search" }) => {
    const [search, setSearch] = useState("");

    const onInputChange = value => {
        setSearch(value);
        onSearch(value);
    };
    return (
        <div className="ai-input-icon">
            <span className="mdi mdi-magnify"></span>
            <input
                type="text"
                className="ai-inputx"
                placeholder={placeholder}
                value={search}
                onChange={e => onInputChange(e.target.value)}
            />
        </div>
    );
};

export default Search;
