import { fork, all } from 'redux-saga/effects';
import { watchSubmitGetTokens } from './userSaga';




export default function* rootSaga() {
    yield all([
        fork(watchSubmitGetTokens),
    ]);
}