import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { ACTION_CONST } from '../../constants';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import { helpers } from '../../utils/helpers';

const WalletInfo = ({ balance, walletAddress }) => {
    const dispatch = useDispatch();
    const [copied, setCopied] = useCopyToClipboard(1000);
    const [showModal, setShowModal] = useState(false);

    const handleLogout = () => {
        dispatch({
            type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
        })
        window.location.reload();
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    return (
        <>
            <button type="button" className='btn-pd' onClick={() => setShowModal(true)}>
                {helpers.formatTransactionHash(walletAddress, 4, 4)} - {balance}
            </button>
            <Modal className='ai-modal' centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Your wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h5 style={{ fontSize: '20px', wordBreak: 'break-word' }} className="mb-3">
                        <b>{walletAddress}</b>
                    </h5>
                    <div className='d-inline-flex'>
                        <span
                            className="d-inline-flex align-items-center"
                            onClick={(e) => {
                                e.preventDefault();
                                setCopied(walletAddress)
                            }}
                            style={{ cursor: 'pointer' }}>
                            <span className="me-1">Copy Address</span>
                            <i className={!copied ? 'far fa-clone me-1' : 'fas fa-check me-1'}></i>
                        </span>
                    </div>
                    <div className="mt-5">
                        <button type="button" className='btn-pd mx-auto' onClick={handleLogout}>
                            <span>Disconnect</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default WalletInfo;