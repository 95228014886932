import React from 'react';

export default function ButtonStep({ divClass, btnText }) {
    return (
        <button type="button" className={`ai-step-trigger ${divClass}`} >
            <span>
                {btnText}
            </span>
        </button>
    );
}
