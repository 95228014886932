import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isMobile } from 'web3modal';
import { ACTION_CONST, NETWORK_LIST } from '../../constants';
import { extensionName } from "../../constants/values";
import {
    isBinanceExtensionAvailable,
    isMetamaskAvailable,
    isTrustWalletAvailable,
    isWeb3NetWorkSupport
} from "../../utils/utils";
import WalletExtensionUtils from "../../utils/walletExtensionUtils";

const defaultInputNetwork = NETWORK_LIST.find(e => e.defaultInput === true);
const ConnectWallet = ({ className = '' }) => {
    const dispatch = useDispatch();
    const [hasMetamask, setHasMetamask] = useState(false);
    const [hasTrustWallet, setHasTrustWallet] = useState(false);
    const [hasBinanceWallet, setHasBinanceWallet] = useState(false);
    const currentNetWork = useSelector((state) => get(state, "wallet.currentInputNetwork", defaultInputNetwork.id));

    const [modalConnectBSCTitle, setModalBSCConnectTitle] = useState("")
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            addWallet();
        }, 3000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNetWork]);

    const addWallet = () => {
        // console.log("currentNetWork: ", currentNetWork);
        if (currentNetWork === 'BSC') {
            setHasMetamask(isMetamaskAvailable());
            setHasTrustWallet(isTrustWalletAvailable());
            setHasBinanceWallet(isBinanceExtensionAvailable());

        } else if (currentNetWork === 'ETH'|| currentNetWork === 'PLS'|| currentNetWork === 'VELAS') {
            setHasMetamask(isMetamaskAvailable());
            setHasTrustWallet(isTrustWalletAvailable());
            setHasBinanceWallet(false);
        } else {
            setHasMetamask(false);
            setHasTrustWallet(false);
            setHasBinanceWallet(false);
        }
    };

    useEffect(() => {

        if (isMobile() && !hasBinanceWallet && !hasMetamask && !hasTrustWallet) {
            setModalBSCConnectTitle("Please open site in Wallet app")
        }
        else {
            setModalBSCConnectTitle("Connect to wallet")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, hasBinanceWallet, hasBinanceWallet, hasTrustWallet])

    useEffect(() => {
        if (currentNetWork === 'BSC') {
            setHasMetamask(isMetamaskAvailable());
            setHasTrustWallet(isTrustWalletAvailable());
            setHasBinanceWallet(isBinanceExtensionAvailable());

        } else if (currentNetWork === 'ETH' || currentNetWork === 'PLS') {
            setHasMetamask(isMetamaskAvailable());
            setHasTrustWallet(isTrustWalletAvailable());
            setHasBinanceWallet(false);
        }
        else {
            setHasMetamask(false)
            setHasTrustWallet(false);
            setHasBinanceWallet(false);
        }
        // console.log("currentNetWork=>", currentNetWork);
    }, [currentNetWork])

    const connectWithExtension = async (extension) => {
        if (extension === extensionName.tronLink && currentNetWork === 'BSC') {
            toast.warn(`Wrong network! You need connect to ${NETWORK_LIST.find(e => e.id === currentNetWork).name}!`);
            return;
        }
        if ((extension === extensionName.metamask || extension === extensionName.binanceExtension || extension === extensionName.trustWallet) && currentNetWork === 'TRON') {
            toast.warn(`Wrong network! You need connect to ${NETWORK_LIST.find(e => e.id === currentNetWork).name}!`);
            return;
        }
        const temp = new WalletExtensionUtils(extension);
        //Connect action
        await temp.connect(currentNetWork);
        if (temp.checkWrongNetwork()) {
            toast.warn(`Wrong network! You need connect to ${NETWORK_LIST.find(e => e.id === currentNetWork).name}!`);
            return;
        }

        dispatch({
            type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
            data: temp
        })

        dispatch({
            type: ACTION_CONST.CURRENT_NET_WORK_EXTENSION,
            data: temp.getCurrentChainId()
        })

        await getBalanceAndAddress(temp);

        try {
            temp.accountsChanged(async (res) => {
                if (res !== undefined) {
                    dispatch({
                        type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
                        data: temp
                    })
                    await getBalanceAndAddress(temp);
                }
            });
            return true;
        } catch (e) {
            dispatch({
                type: ACTION_CONST.REQUEST_DONE
            })
            console.log("error: ", e);
        }

        //if chain ID
        try {
            temp.chainChanged(async (chanId) => {
                await temp.connect(currentNetWork);
                if (temp.checkWrongNetwork()) {
                    toast.warn("Wrong network! You need connect to Binance smart chain network!");
                    return;
                }
                dispatch({
                    type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
                    data: temp
                })

                dispatch({
                    type: ACTION_CONST.CURRENT_NET_WORK_EXTENSION,
                    data: temp.getCurrentChainId()
                })

                await getBalanceAndAddress(temp);
            })
        } catch (error) {

        }

    };
    const getExtension = () => {
        return extensionName;
    };

    const getBalanceAndAddress = async (extension) => {
        const walletAddress = await extension.getCurrentAddress();
        dispatch({
            type: ACTION_CONST.CONNECT_WALLET_SUCCESS,
            data: walletAddress
        })
    };

    const handleCloseModal = () => {
        setShowModal(false);
    }

    return (
        <>
            <button className={`btn-connect ${className}`} type="button" onClick={() => setShowModal(true)}>
                <span>Connect wallet</span>
            </button>
            <Modal size='sm' className='ai-modal' centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            isWeb3NetWorkSupport(currentNetWork) ? modalConnectBSCTitle : ""
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                        {
                            isWeb3NetWorkSupport(currentNetWork) ? <>
                                {
                                    hasMetamask ?
                                        <button
                                            type='button'
                                            className="btn-pd btn-connect w-100"
                                            onClick={() => {
                                                connectWithExtension(getExtension().metamask);
                                                setShowModal(false);
                                            }}
                                        >
                                            <img className="me-2" src="/images/metamask.svg" height="26" alt="Metamask" />
                                            <span>Metamask</span>
                                        </button> : <></>
                                }
                                {
                                    hasBinanceWallet ?
                                        <button
                                            type='button'
                                            className="btn-pd btn-connect w-100"
                                            onClick={() => {
                                                connectWithExtension(getExtension().binanceExtension);
                                                setShowModal(false);
                                            }}
                                        >
                                            <img className="me-2" src="/images/binance-extension.png" height="26" alt="Binance" />
                                            <span>Binance Chain Wallet</span>
                                        </button> : <></>
                                }
                                {
                                    hasTrustWallet ?
                                        <button
                                            type='button'
                                            className="btn-pd btn-connect w-100"
                                            onClick={() => {
                                                connectWithExtension(getExtension().trustWallet);
                                                setShowModal(false);
                                            }}
                                        >
                                            <img className="me-2" src="/images/trust_platform.png" height="26" alt="Trust Wallet" />
                                            <span>Trust Wallet</span>
                                        </button> : <></>
                                }
                            </> : <></>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ConnectWallet;